/*
 * © 2023-2024 Nqobani Vuka Dlamini
 * All rights reserved.
 */
import "../Style/SignUp3.css";
import { useState,useRef } from "react";
import { Link} from "react-router-dom";
import { UserDataContext } from "../Data/Session";
import { useContext } from "react";
import { updateServicer } from "../Actions/Servicer"; 

function SignUp3(){
    const {userData, sessionId} = useContext(UserDataContext);
    const [display2,setD2] = useState("none");
    const [display4,setD4] = useState("none");
    const [display5,setD5] = useState("none");

    const [selected, setSelected] = useState(0);
    const [servicerData, setData] = useState({description:'',imgFile:null,service:'House Cleaner'});
    const [image, setImage] = useState(null)

    const linkRef1 = useRef();

    const handleChange1 = (e)=>{
        const {name,value} = e.target;
        
        setData({
            ...servicerData,
            [name]:value
        });

    }

    const handleChange2 = (selection, index)=>{
        setData({...servicerData,service:selection});
        setSelected(index);
    }
    
    const handleImage = (e) =>{
        const file = e.target.files[0];
        const reader = new FileReader();

        setData({...servicerData,imgFile:file});
        reader.onloadend = () => {
            setImage(reader.result);
        };
        reader.readAsDataURL(file);
    }

    const handleSubmit = async(e)=>{
        e.preventDefault();

        if(userData && userData.type=='Servicer'){
            updateServicer(userData._id,{...servicerData, sessionId:sessionId});
            if(linkRef1.current){
                linkRef1.current.click();
            }
        }
    }

    const output = (<>
                        <div id="newSellerPage2">
                            <div className="logo-block">
                                <img src="/Images/logo3.png"></img>
                            </div>
                            <div className="emailUnderliner">
                                <div className="euLine1"></div>
                                <div className="euLine2"></div>
                            </div>
                            <div className="inputSection1">
                                <form onSubmit={handleSubmit}  enctype="multipart/form-data">
                                    <h2>Complete your account creation</h2>
                                    <div className="s4Underliner">
                                        <div className="s4UL1"></div>
                                        <div className="s4UL2"></div>
                                    </div>
                                    <div className='profileInput'><img src={(image)?image:"/Images/user.png"}></img></div>
                                    <div className='label-holder'>
                                        <p className="s4TXT2">Enter a self portrait:</p>
                                        <p className="reqTag" style={{display:display2}}>required</p>
                                    </div>
                                    <input type="file" name='imgFile' className="s4Input2 fileInput" onChange={handleImage}></input>
                                    <div className='label-holder'>
                                        <p className="s4TXT4">Enter a description of your services:</p>
                                        <p className="reqTag" style={{display:display4}}>required</p>
                                    </div>
                                    <textarea maxlength="220" name='description' className="s4Input4" onChange={handleChange1}></textarea>
                                    <div className='label-holder'>
                                        <p className="s4TXT6">Select your title:</p>
                                        <p className="reqTag" style={{display:display5}}>required</p>
                                    </div>
                                    <div className="s4Stack s4s1">
                                        <div className="isInput" onClick={()=>handleChange2('House Cleaner',0)} style={{backgroundColor: (selected==0)?'lightblue':'white'}}>
                                            <p style={{color: (selected==0)?'white':'black'}}>House Cleaner</p>
                                        </div>
                                        <div className="isInput"  onClick={()=>handleChange2('Painter',1)} style={{backgroundColor: (selected==1)?'lightblue':'white'}}>
                                            <p style={{color: (selected==1)?'white':'black'}}>Painter</p>
                                        </div>
                                        <div className="isInput"  onClick={()=>handleChange2('Pool Cleaner',2)} style={{backgroundColor: (selected==2)?'lightblue':'white'}}>
                                            <p style={{color: (selected==2)?'white':'black'}}>Pool Cleaner</p>
                                        </div>
                                        <div className="isInput"  onClick={()=>handleChange2('Gardener',3)} style={{backgroundColor: (selected==3)?'lightblue':'white'}}>
                                            <p style={{color: (selected==3)?'white':'black'}}>Gardener</p>
                                        </div>
                                        <div className="isInput"  onClick={()=>handleChange2('Electrician',4)} style={{backgroundColor: (selected==4)?'lightblue':'white'}}>
                                            <p style={{color: (selected==4)?'white':'black'}}>Electrician</p>
                                        </div>
                                        <div className="isInput"  onClick={()=>handleChange2('Plumber',5)} style={{backgroundColor: (selected==5)?'lightblue':'white'}}>
                                            <p style={{color: (selected==5)?'white':'black'}}>Plumber</p>
                                        </div>
                                        <div className="isInput"  onClick={()=>handleChange2('Car Cleaner',6)} style={{backgroundColor: (selected==6)?'lightblue':'white'}}>
                                            <p style={{color: (selected==6)?'white':'black'}}>Car Cleaner</p>
                                        </div>
                                        <div className="isInput"  onClick={()=>handleChange2('Pet Caretaker',7)} style={{backgroundColor: (selected==7)?'lightblue':'white'}}>
                                            <p style={{color: (selected==7)?'white':'black'}}>Pet Caretaker</p>
                                        </div>
                                        <div className="isInput"  onClick={()=>handleChange2('Waste Remover',8)} style={{backgroundColor: (selected==8)?'lightblue':'white'}}>
                                            <p style={{color: (selected==8)?'white':'black'}}>Waste Remover</p>
                                        </div>
                                        <div className="isInput"  onClick={()=>handleChange2('Pest Controller',9)} style={{backgroundColor: (selected==9)?'lightblue':'white'}}>
                                            <p style={{color: (selected==9)?'white':'black'}}>Pest Controller</p>
                                        </div>
                                        <div className="isInput"  onClick={()=>handleChange2('Guuter Cleaner',10)} style={{backgroundColor: (selected==10)?'lightblue':'white'}}>
                                            <p style={{color: (selected==10)?'white':'black'}}>Gutter Cleaner</p>
                                        </div>
                                    </div>
                                        
                                    <button className='s4Button' type="Submit">Submit</button>

                                </form>
                                <Link ref={linkRef1} style={{display:'none'}} to='/Account/user'></Link>
                            </div>         
                        </div>
                    </>
    );

    return output;
}

export default SignUp3;