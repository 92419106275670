/*
 * © 2023-2024 Nqobani Vuka Dlamini
 * All rights reserved.
 */
import { Outlet, Link } from "react-router-dom";
import "./Style/Layout.css";
import { useEffect, useRef, useState } from "react";
import { UserDataContext } from "./Data/Session";
import { useContext } from "react";

export default function Layout(){
    const {userData} = useContext(UserDataContext);
    const [width, setWidth] = useState('0px');

    const output = (<div id="Layout">
                        {/* <div className="Header">
                            <Link to='/services'><div className="left-links"><img src="/Images/cart2.png"></img><p>Services</p></div></Link>
                            <Link to='/account/user'><div className="left-links"><img src="/Images/user2.png"></img><p>Account</p></div></Link>
                            <Link to='/search'><div className="left-links"><img src="/Images/searchIcon.png"></img><p>Search</p></div></Link>
                            <div className="logo"><img src="/Images/lulo-logo.png"></img></div>
                            <Link to='/'><div className="right-links"><p>Home</p></div></Link>
                            <Link to='/aboutUs'><div className="right-links"><p>About Us</p></div></Link>
                            <Link to='/signIn'><div className="signup-link"><button>Sign Up</button></div></Link>
                        </div> */}
                        <div className="Header2">
                            <div className="logo2"><img src="/Images/logo3.png"></img></div>
                            <Link to={(userData)?'/services':'/signIn'}><div className="left-links"><img src="/Images/cart2.png"></img><p>Services</p></div></Link>
                            <Link to={(userData)?'/account/user':'/signIn'}><div className="left-links"><img src="/Images/user2.png"></img><p>Account</p></div></Link>
                            <Link to={(userData)?'/search':'/signIn'}><div className="left-links"><img src="/Images/searchIcon.png"></img><p>Search</p></div></Link>
                            <div></div>
                            <Link to='/'><div className="right-links"><p>Home</p></div></Link>
                            <Link to='/aboutUs'><div className="right-links"><p>About Us</p></div></Link>
                            <Link to={(userData)?'/':'/signIn'}><div className="signup-link"><button>{(userData)?userData.name:'Sign Up'}</button></div></Link>
                            <img src='/Images/menu.png' className="menu" onClick={()=>setWidth('200px')}></img>
                        </div>
                        <div className="menu-tab" style={{width:width}}>
                            <div className='top-block'>
                                <p className="close-menu" onClick={()=>setWidth('0px')}>&#10006;</p>
                            </div>
                            <div className='bottom-block'>
                                <Link to={(userData)?'/services':'/signIn'}><div className="menu-link"><img src="/Images/cart2.png"></img><p>Services</p></div></Link>
                                <Link to={(userData)?'/account/user':'/signIn'}><div className="menu-link"><img src="/Images/user2.png"></img><p>Account</p></div></Link>
                                <Link to={(userData)?'/search':'/signIn'}><div className="menu-link"><img src="/Images/searchIcon.png"></img><p>Search</p></div></Link>
                                <Link to='/'><div className="menu-link"><img src="/Images/home.png"></img><p>Home</p></div></Link>
                                <Link to='/aboutUs'><div className="menu-link"><img src="/Images/about-us.png"></img><p>About Us</p></div></Link>
                            </div>
                        </div>
                        <Outlet/>
                        <div className="Footer">
                            <div className="linksBlock">
                                <div className='categories'>
                                    <div className="helpBlock">
                                        <h3 className="heading1">Help</h3>
                                        <Link className="textLink" to="/"><p >You can call or email us</p></Link>
                                        <Link className="textLink" to="/"><p >FAQ's</p></Link>
                                    </div>
                                    <div className="aboutBlock">
                                        <h3 className="heading2">About Us</h3>
                                        <Link className="textLink" to="/aboutUs"><p >Latest News</p></Link>
                                        <Link className="textLink" to="/aboutUs"><p >Magazine</p></Link>
                                    </div>
                                    <div className="connectBlock">
                                        <h3 className="heading3">Connect</h3>
                                        <Link className="socials" to="/"><img src="/Images/facebook.jpeg"></img></Link>
                                        <Link className="socials" to="/"><img src="/Images/insta.jpeg"></img></Link>
                                        <Link className="socials" to="/"><img src="/Images/X.png"></img></Link>
                                        <Link className="socials" to="/"><img src="/Images/yTube.png"></img></Link> 
                                    </div>
                                </div>
                            </div>
                            <div className="termsBlock">
                                <div className='terms-links'>    
                                    <div className="tcLink"><Link to="/"><p>Terms & Conditions</p></Link></div>
                                    <div className="ppLink"><Link to="/"><p>Privacy Policy</p></Link></div>
                                </div>
                            </div>
                            <div className="posterBlock">
                                <img src='/Images/logo3.png'></img>
                            </div>
                        </div>
                    </div>
                    );

    return output;
}