import "../Style/Thumbnail4.css";
import {useState, useEffect, useContext} from "react";
import { updateUser } from "../Actions/User";
import { UserDataContext } from "../Data/Session";
import { useNavigate } from "react-router-dom";

export default function Thumbnail4({servicer}){
    const [likeImg, setLike] = useState('/Images/heart.png');
    const [liked,setLiked] = useState(false);
    const {userData, sessionId, ratings} = useContext(UserDataContext);
    const navigate = useNavigate();
    const [rating, setRating] = useState([false,false,false,false,false])
    
    function handleNull(){
    }
    function handleClick0(){
        if(!liked){
            setLiked(true);
            setLike('/Images/red-heart.png');
            const saved = userData.saved;
            updateUser(userData._id,{sessionId:sessionId,saved:[...saved,servicer._id]})
        }
        else{
            setLiked(false);
            setLike('/Images/heart.png');
            const saved = userData.saved.filter((id)=>(id!=servicer._id))
            updateUser(userData._id,{sessionId:sessionId,saved:saved})
        }
    }

    useEffect(()=>{
        if(userData && userData.saved){
            const like = userData.saved.find((id)=>(id==servicer._id));
            if(like!=null && like!={}){
                setLike('/Images/red-heart.png');
                setLiked(true);
            }
        }
    },[userData])

    useEffect(()=>{
        if(ratings && servicer){
            const ratingArr = ratings.filter((rating)=>rating.servicerId==servicer._id);
            let ratingAve = 0;
            for(let i = 0; i<ratingArr.length; i++){
                ratingAve += ratingArr[i].rating;
            }
            ratingAve = (ratingArr.length>0)?Math.round(ratingAve/ratingArr.length):0;
            
            switch(ratingAve){
                case 0:
                    setRating([false,false,false,false,false]);
                    break;
                case 1:
                    setRating([true,false,false,false,false]);
                    break;
                case 2:
                    setRating([true,true,false,false,false]);
                    break;
                case 3:
                    setRating([true,true,true,false,false]);
                    break;
                case 4:
                    setRating([true,true,true,true,false]);
                    break;
                case 5:
                    setRating([true,true,true,true,true]);
                    break;
            }
        }
    },[ratings,servicer])

    const output = (<div id="Thumbnail4">
                        <div className="sellerInfo">
                            <div  className="ImgHolder2">
                                <img src={(servicer.profileLink && servicer.profileLink!='')?servicer.profileLink:"/Images/user.png"} className="thumbImg"></img>
                            </div>
                            <p className="name2 a">{servicer.name} {servicer.surname}</p>
                            <p className="jobs a">{(servicer.jobs)?servicer.jobs:'0'} Jobs</p>
                            <div className="location">
                                <img src="/Images/pin.png" className="pin"></img>
                                <p className="a1">{(servicer.city)?servicer.city:'-'}</p>
                            </div>
                            <div className="comment-like">
                                <img className="like" src={likeImg} onClick={(userData.type=='User')?handleClick0:handleNull}></img>
                                <img className="comment" src="/Images/chat.png"></img>
                            </div>
                        </div>
                        <div className="serviceSpec" onClick={()=>(userData.type=='User')?navigate(`/account/servicer/${servicer._id}`):handleNull}>
                            <div className="title-block">
                                <h1 className="title">{servicer.service}</h1>    
                            </div>
                            <p className="text">{servicer.description}</p>
                        </div>
                        <div className="rating">
                            <img src={(rating[0])?"/Images/star-yellow.png":"/Images/star.png"} className="star"></img>
                            <img src={(rating[1])?"/Images/star-yellow.png":"/Images/star.png"} className="star"></img>
                            <img src={(rating[2])?"/Images/star-yellow.png":"/Images/star.png"} className="star"></img>
                            <img src={(rating[3])?"/Images/star-yellow.png":"/Images/star.png"} className="star"></img>
                            <img src={(rating[4])?"/Images/star-yellow.png":"/Images/star.png"} className="star"></img>                            
                        </div>
                    </div>);
    
    return output
}