import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import Account from "./Pages/Account";
import Layout from "./Layout";
import Search from './Pages/Search';
import Services from './Pages/Services';
import AboutUs from './Pages/AboutUs';
import SignIn from './Pages/signIn';
import SignUp1 from './Pages/signUp1';
import SignUp2 from './Pages/signUp2';
import SignUp3 from './Pages/SignUp3';
import Checkout from './Pages/Checkout';
import Booking from './Pages/Booking';
import {Session} from "./Data/Session";
import ErrorPage from './Pages/ErrorPage';

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Session/>}>
          <Route path='/signIn' element={<SignIn/>}/>
          <Route path='/signUp1' element={<SignUp1/>}/>
          <Route path='/signUp2/:email' element={<SignUp2/>}/>
          <Route path='/signUp3' element={<SignUp3/>}/>
          <Route path='*' element={<ErrorPage/>}/>
          <Route element={<Layout/>}>
            <Route index element={<Home />} />
            <Route path='/account/:accountType/:servicerId' element={<Account />} />
            <Route path='/account/:accountType' element={<Account />} />
            <Route path='/search' element={<Search />} />
            <Route path='/services' element={<Services />} />
            <Route path='/aboutUs' element={<AboutUs />} />
            <Route path='/checkout' element={<Checkout />} />
            <Route path='/booking/:bookingId' element={<Booking />} />
          </Route>   
        </Route>  
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);


