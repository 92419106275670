import "../Style/SignUp1.css";
import { Link } from "react-router-dom";
import { useState,useEffect,useRef, useContext} from "react";
import { signUp1 } from "../Actions/User";
import { UserDataContext } from "../Data/Session";

function SignUp1(){
    const {users, servicers} = useContext(UserDataContext);
    const [isFocused, setFocused] = useState(false);
    const [display1,setDisplay1] = useState('none');
    const [display2,setDisplay2] = useState('none');
    const [inputValue, setInputValue] = useState({
        email: ''
    });
    
    const linkRef = useRef();

    function handleBlur(){
        setFocused(false);
    }

    function handleFocus(){
        setFocused(true);
        setDisplay1('none');
        setDisplay2('none');
    }

    function handleChange(e){
        setInputValue({email: e.target.value});
        setDisplay1('none');
    }

    const handleSubmit = async(e)=>{
        e.preventDefault();
        const unavailable1 = users.find((user)=>user.email==inputValue.email);
        const unavailable2 = servicers.find((servicer)=>servicer.email==inputValue.email);

        if(unavailable1 || unavailable2){
            setDisplay2('block');
        }
        else{
            const response = await signUp1(inputValue);
            if(response.data.noEmail){
                setDisplay1('block');
            }
            else{
                linkRef.current.click();
            }
        }
    }

    const output = (<>
                        <div id="emailPage">
                            <div className="logo-block">
                                <img src="/Images/logo3.png"></img>
                            </div>
                            <div className="emailUnderliner">
                                <div className="euLine1"></div>
                                <div className="euLine2"></div>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <h2>Enter your email to sign up.</h2>
                                <div className="email">
                                    <input id='emailInput' type="text" name="email" onFocus={handleFocus} onBlur={handleBlur} onChange={handleChange}></input>
                                    <p className="required" style={{display:display1}}>required</p>
                                    <p className="required" style={{display:display2}}>already in use</p>
                                    <label htmlFor="emailInput" className={`emailPlaceholder ${isFocused || inputValue.email ? 'active':''}`}>
                                        Email
                                    </label>
                                </div>
                                <div className="link">
                                    <div>
                                        <p>By continuining, I agree to ScheduleEase's</p>
                                        <Link to='/account'>Privacy Policy</Link>
                                        <p>and</p>
                                        <Link to='/account'>Terms of Use.</Link>
                                    </div>
                                </div>
                                <div className="submit">
                                    <button type='submit'>
                                        Continue
                                    </button>
                                </div>
                                
                            </form>    
                            <Link ref={linkRef} to={`/signUp2/${inputValue.email}`}></Link>
                        </div>
        
                    </>);

    return output;
}

export default SignUp1; 