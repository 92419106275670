import axios from 'axios';

const url = 'https://scheduleease.co.za/api/card/';

///////////////////////////// GET REQUEST /////////////////////////////////////

export const getCards = async() => {
    try {
        const {data} = await axios.get(url);
        return data;
    } catch (error) {
        console.log(error)
    }
}

////////////////////////////// POST REQUEST ///////////////////////////////////

export const authorizeCard = async(info) =>{
    try {

        const {data} = await axios.post(`${url}authorize`,info);
        return data;

    } catch (error) {
      console.log(error); 
      return {retry:false};
    }
}

export const addCard = async(info) =>{
    try {
       
        const {data} = await axios.post(url,info);
        return data;

    } catch (error) {
      console.log(error); 
      return {retry:false};
    }
}

export const pay = async(info) =>{
    try {

        const {data} = await axios.post(`${url}pay`,info);
        return data;

    } catch (error) {
        console.log(error);
        return {retry:false};
    }

}

///////////////////////////////// Update REQUEST /////////////////////////////////////////

export const updateCard = (id,data) =>{
    try {
        axios.patch(`${url}${id}`,data);
    } catch (error) {
      console.log(error); 
    }
}

//////////////////////////////// Delete REQUEST //////////////////////////////////////////

export const deleteCard = (id)=> {
    try {
        axios.delete(`${url}${id}`);
    } catch (error) {
        console.log(error);
    }
}