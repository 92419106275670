import { Link } from 'react-router-dom';
import '../Style/Message.css';
import { useState, useRef, useEffect } from 'react'; 
import { createMessage, updateMessage } from '../Actions/Message';
import { useContext } from 'react';
import { UserDataContext } from '../Data/Session';

export default function Message({setMessage, messenger, type}){
     const textRef = useRef();
     const containerRef = useRef();
     const [text, setText] = useState('');
     const [image, setImage] = useState(null);
     const [imgFile, setFile] = useState(null);
     const [Messages, setMessages] = useState(null);
     const {userData, messages} = useContext(UserDataContext);
     const anchorRef = useRef();

    const Submit = ()=>{
        if(imgFile){
            createMessage({createdDate:new Date().getTime(), receiverId:messenger._id, senderId:userData._id, message:text, imgFile:imgFile, seen:false, sent:true})
            setText('');
            setFile(null);
            setImage(null);
            textRef.current.style.height = `52px`;
            containerRef.current.style.height = `65px`;
        }
        else{
            createMessage({createdDate:new Date().getTime(), receiverId:messenger._id, senderId:userData._id, message:text, seen:false, sent:true})
            setText('');
            textRef.current.style.height = `52px`;
            containerRef.current.style.height = `65px`;
        }
    }

    function formatTime(date) {
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${hours}:${minutes}`;
    }

    const handleImage = (e) =>{
        const file = e.target.files[0];
        const reader = new FileReader();

        if(file){
            setFile(file);
            reader.onloadend = () => {
                setImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    }

    const removeImage = () =>{
        setImage(null);
        setFile(null);
    }

    const groupMessagesByDay = (messages) => {
        const groupedMessages = {};
        messages.forEach(message => {
          const dateKey = new Date(message.createdDate).toDateString(); // Format date as string (e.g., "Mon Sep 4 2023")
          if (!groupedMessages[dateKey]) {
            groupedMessages[dateKey] = [];
          }
          groupedMessages[dateKey].push(message);
        });
        return groupedMessages;
    };

    // useEffect(()=>{
    //     if (textRef.current && textRef.current.scrollHeight<200) {
    //         textRef.current.style.height = 'auto'; 
    //         textRef.current.style.height = `${textRef.current.scrollHeight}px`;
    //         containerRef.current.style.height = `${textRef.current.scrollHeight+15}px`
    //     }
    // },[text])

    useEffect(()=>{
        if(messages){
            const data = messages.filter((message)=>((message.senderId==userData._id && message.receiverId==messenger._id) || (message.senderId==messenger._id && message.receiverId==userData._id)))
            setMessages(groupMessagesByDay(data));

            for(let i=0; i<data.length; i++){
                if(data[i].receiverId==userData._id && !data[i].seen){
                    updateMessage(data[i]._id,{seen:true});
                }
            }
            
        }
    },[messages])

    useEffect(()=>{
        const Anchor = ()=>{
            if(anchorRef.current) {
                anchorRef.current.scrollIntoView({ behavior: 'auto' });
            }
        }
        // Delay scrolling to ensure it happens after rendering
        const timeoutId = setTimeout(Anchor, 0);

        return () => clearTimeout(timeoutId);
    },[Messages])

    const output = (<div id='message-page'>
                        <div className='Message'>
                            <div className="message-header">
                                <p className='close-message' onClick={()=>setMessage(false)}>&#10006;</p>
                                <div className='profile-frame'>
                                    <img src={(messenger && messenger.profileLink && messenger.profileLink!='')?messenger.profileLink:"/Images/user.png"}></img>
                                </div>
                                <h3>{(messenger)?messenger.name:''} {(messenger)?messenger.surname:''}</h3>
                                {type=='servicer' &&(
                                    <p>{`- ${(messenger.service)?messenger.service:" "} -`}</p>
                                )}
                                {/* <img className='video-call' src='/Images/video2.png'></img>
                                <img className='voice-call' src='/Images/telephone.png'></img> */}
                            </div>
                            <div className='messages-block'>
                                {Messages && Object.keys(Messages).map((dateKey) => (
                                    <>
                                    <div className='date-card'>
                                        <p>{dateKey}</p>
                                    </div>

                                    {Messages[dateKey].map((message) => (
                                        !message.imgLink ? (
                                        <div className={`messageCard ${(message.senderId == userData._id) ? 'sender' : 'receiver'}`}>
                                            <p className='text'>{message.message}</p>
                                            <p className='time'>{formatTime(new Date(message.createdDate))}</p>
                                        </div>
                                        ) : (!message.message || message.message=='' ? (
                                            <div className={`picCard1 ${(message.senderId == userData._id) ? 'pic1-sender' : 'pic1-receiver'}`} >
                                                <img src={message.imgLink}></img>
                                                <p className='time-pic'>{formatTime(new Date(message.createdDate))}</p>
                                            </div>
                                            ) : (
                                            <div className={`picCard2 ${(message.senderId == userData._id) ? 'pic2-sender' : 'pic2-receiver'}`} >
                                                <div className={`picSection ${(message.senderId == userData._id) ? 'ps-sender' : 'ps-receiver'}`}>    
                                                    <img src={message.imgLink}></img>
                                                </div>
                                                <div className='textSection'>
                                                    <p className='text-pic'>{message.message}</p>
                                                    <p className='time-pic'>{formatTime(new Date(message.createdDate))}</p>
                                                </div>
                                            </div>
                                            )
                                        )
                                    ))}
                                    </>
                                ))}
                                <div ref={anchorRef} className='messages-anchor'></div>
                            </div>
                            <div className='chat-block' ref={containerRef}>
                                <div className='camera-frame'>
                                    <img src='/Images/camera.png'></img>
                                    <input type='file'  accept="image/*" capture="environment" onChange={handleImage} value={(imgFile)?imgFile.filename:''}></input>
                                </div>
                                <textarea value={text} ref={textRef} className='chat-box' onChange={(e) => setText(e.target.value)}></textarea>
                                <div className='share-image'>
                                    <img src='/Images/picture.png'></img>
                                    <input type='file'  accept="image/*"  onChange={handleImage} value={(imgFile)?imgFile.filename:''}></input>
                                </div>
                                {/* {text=='' &&(
                                    <img className='voice-message' src='/Images/mic.png'></img>
                                )} */}
                                {(text!='' || image) &&(
                                   <div className='send' onClick={()=>Submit()}><img src='/Images/send.png'></img></div>
                                )}
                                {image && (
                                    <div className='Image-Display'>
                                        <img src={image}></img>
                                        <p className='remove-image' onClick={removeImage}>&#10006;</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    );

    return output;
}