import '../Style/CancelBooking.css';
import { updateBooking } from '../Actions/Booking';

export default function CancelBooking({setCancelBooking, booking, canceller}){

    const output = (<div id='CancelBooking'>
                        <div>
                            <h1>Are you sure you want to cancel this booking?</h1>
                            <button className='Delete' onClick={()=>{updateBooking(booking._id,{canceller:canceller, cancelType:'General'}); setCancelBooking(false);}}>Yes</button>
                            <button className='Cancel' onClick={()=>setCancelBooking(false)}>No</button>
                        </div>
                    </div>)

    return output;

}