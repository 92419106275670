import '../Style/Checkout.css';
import { useState, useEffect } from 'react';
import { useContext } from 'react';
import { UserDataContext } from '../Data/Session';
import CancelBlock from '../PopUps/CancelBlock';
import CardHandler from '../PopUps/CardHandler';
import RemoveCard from '../PopUps/RemoveCard';
import LocationSelector from '../Components/LocationSelector';
import { pay } from '../Actions/Card';

export default function Checkout(){
    const {bookings, servicers, userData, ratings, cards, sessionId} = useContext(UserDataContext);
    const [myBooking, setBooking] = useState(null);
    const [servicer, setServicer] = useState(null)
    const [typesOpen, setOpen] = useState(false);
    const [top, setTop] = useState([70,110]);
    const [height, setHeight] = useState('0px');
    const [height2, setHeight2] = useState('0px')
    const [typeTxt, setText] = useState('House');
    const [paymentOption, setOption] = useState('Card');
    const [cardsOpen, setCardsOpen] = useState(false);
    const [deleteCard, setDeleteCard] = useState(false);
    const [toBeDeleted, setToBeDeleted] = useState(0);
    const [selectedCard, setCard] = useState(0);
    const [rating, setRating] = useState(0);
    const [jobs, setJobs] = useState(null);
    const [CancelOpen, setCancelOpen] = useState(false);
    const [AddCard, setAddCard] = useState(false);
    const [myCards, setCards] = useState([]);
    const [Address, setAddress] = useState({buildingType:'House',line1: null, line2: null, line3: null, line4: null, line5:null,note:''});
    const houseSlots = ['','8:00-12:00','13:00-17:00'];
    const carSlots = ['','7:00-8:00','9:00-10:00','11:00-12:00','13:00-14:00','15:00-16:00'];
    const gardenSlots = ['','7:00-11:00','12:00-16:00'];
    const poolSlots = ['','8:00-10:00','11:00-13:00','14:00-16:00'];
    const negotiableSlots = ['','7:00-11:00','12:00-16:00'];


    const cardBrandToIcon = {
        visa: '/Images/visa-blue.png',
        mastercard: '/Images/mastercard.png',
        null : '/Images/bank-card.png', // fallback for unknown brand
    };

    function Pay(){
        let allGood = true;
       
        if(!Address.line1){
            allGood = false;
        }
        if(!Address.line2){
            allGood = false;
        }
        if(Address.buildingType!='House' && !Address.line3){
            allGood = false;
        }
        if(Address.buildingType!='House' && Address.buildingType!='Complex / Estate' && !Address.line4){
            allGood = false;
        }
        if(allGood){
            if(paymentOption=='Card' && myCards.length>0){
                // console.log('Paying!!!')
                // const data = {
                //                 address: {...Address,city:(userData.city)?userData.city:null},
                //                 bookingId: myBooking._id,
                //                 paymentOption: paymentOption,
                //                 card: myCards[selectedCard],
                //                 sessionId: sessionId,
                //                 amount: myBooking.amount+30
                //             };

                // pay(data);
            }
            else if(paymentOption=='Cash'){
                // const data = {
                //                 address: {...Address,city:(userData.city)?userData.city:null},
                //                 bookingId: myBooking._id,
                //                 paymentOption: paymentOption,
                //                 sessionId: sessionId
                //             };

                // pay(data);
            }
        }
    }

    function BType(type){
        switch(type){
            case 0:
                setText('House');
                setTop([70,110]);
                setAddress({...Address,buildingType:'House'})
                break
            case 1:
                setText('Apartment');
                setTop([110,150]);
                setAddress({...Address,buildingType:'Apartment'})
                break
            case 2:
                setText('Complex / Estate');
                setTop([110,150]);
                setAddress({...Address,buildingType:'Complex / Estate'})
                break
            case 3:
                setText('Office');
                setTop([110,150]);
                setAddress({...Address,buildingType:'Office'})
                break
        }
    }

    function CarCost(data){
        let cost = 0;
        if(data.interior){
            cost += 20;
        }
        if(data.polish){
            cost += 30;
        }
        if(data.carType=='Light Vehicle'){
            cost += 80;
        }
        else if(data.carType=='2 Axle Heavy Vehicle'){
            cost += 120;
        }
        else if(data.carType=='3 & 4 Axle Heavy Vehicle'){
            cost += 250;
        }
        
        return cost;
    }

    function Period(slotNo){
        if(myBooking.service == 'House Cleaning'){
            return houseSlots[slotNo];
        }
        else if(myBooking.service == 'Pool Cleaning'){
            return poolSlots[slotNo];
        }
        else if(myBooking.service == 'Car Cleaning'){
            return carSlots[slotNo];
        }
        else if(myBooking.service == 'Gardening'){
            return gardenSlots[slotNo];
        }
    }

    const AddressChange = (e)=>{
        e.preventDefault();
        const {value, name} = e.target;

        setAddress({
            ...Address,
            [name]:value
        })
    }

    useEffect(()=>{
        if(bookings && userData && servicers){
            const data = bookings.find((Data)=>Data.bookerId==userData._id && Data.paidDate==null && Data.completedDate==null);
            setBooking(data);

            if(data){
                const data2 = servicers.find((Data)=>Data._id==data.servicerId);
                setServicer(data2);
            }
        }
    },[bookings, userData, servicers])

    useEffect(()=>{
        if(ratings && servicer){
            const ratingArr = ratings.filter((rating)=>rating.servicerId==servicer._id);
            let ratingAve = 0;
            for(let i = 0; i<ratingArr.length; i++){
                ratingAve += ratingArr[i].rating;
            }
            ratingAve = (ratingArr.length>0)?Math.round(ratingAve/ratingArr.length):0;  
            setRating(ratingAve);  
        }
    },[ratings, servicer])

    useEffect(()=>{
        if(cards && userData){
            setCards(cards.filter((card)=>(card.userId=userData._id)))
        }
    },[cards, userData])

    useEffect(()=>{
        if(bookings && servicer){
            const data = bookings.filter((booking)=>booking.servicerId == servicer._id && booking.progress.stage4a==true && booking.progress.stage4b==true);
            setJobs(data.length);
        }
    },[bookings, servicer])

    if(myBooking && servicer){
        const output = (<div id='Checkout'>
            {CancelOpen && (
                <CancelBlock bookingId={myBooking._id} setCancelOpen={setCancelOpen} ></CancelBlock>
            )}
            {AddCard==true && ( 
                <CardHandler setAddCard={setAddCard} ></CardHandler>
            )}
            {deleteCard==true &&(
                <RemoveCard cardId={myCards[toBeDeleted]._id} setDeleteCard={setDeleteCard} setCard={setCard}></RemoveCard>
            )}
            <div className='settings'>
                <h1 className='page-title'>Checkout</h1>
                <div className='address-block'>
                    <div className='block-title'>
                        <h3>Address</h3>
                    </div>
                    <div className='address-body'>
                        <div className="map">
                            <LocationSelector setAddress={setAddress} Address={Address}></LocationSelector>
                        </div>
                        <div className='address-settings'>
                            <div className='building-type'>
                                <p className='selected-btype'>Building Type : {typeTxt}</p> 
                                <img src={(typesOpen)?'/Images/up-arrow.png':'/Images/down-arrow.png'} onClick={()=>{setOpen(!typesOpen); (typesOpen)?setHeight('0px'):setHeight('200px')}}></img>
                            
                                <div className='types' style={{height:height}}>
                                    <p className='type-card' onClick={()=>BType(0)}>House</p>
                                    <p className='type-card' onClick={()=>BType(1)}>Apartment</p>
                                    <p className='type-card' onClick={()=>BType(2)}>Complex / Estate</p>
                                    <p className='type-card' onClick={()=>BType(3)}>Office</p>
                                </div>
                            
                            </div>
                            {typeTxt=='Apartment' &&(<>   
                                <input name='line3' type='text' className="streetNo" placeholder='Unit No' value={Address.line3} style={{top:'70px'}} onChange={AddressChange}></input> <input name='line4' type='text' className="streetName" placeholder='Apartment Name'  value={Address.line4} style={{top:'70px'}} onChange={AddressChange}></input>
                            </>)}
                            {typeTxt=='Office' &&(<>   
                                <input name='line3' type='text' className="streetNo" placeholder='Floor No' value={Address.line3} style={{top:'70px'}} onChange={AddressChange}></input> <input name='line4' type='text' className="streetName" placeholder='Company Name, Building Name' value={Address.line4} style={{top:'70px'}} onChange={AddressChange}></input>
                            </>)}
                            {typeTxt=='Complex / Estate' &&(<>   
                                <input name='line3' type='text' className="note" placeholder='Complex / Estate Name' value={Address.line3} style={{top:'70px'}} onChange={AddressChange}></input>
                            </>)}
                            <input name='line1' type='text' className="streetNo" placeholder='Str No' value={Address.line1} style={{top:top[0]}} onChange={AddressChange}></input> <input name='line2' type='text' className="streetName" placeholder='Street Name' value={Address.line2} style={{top:top[0]}} onChange={AddressChange}></input>
                            <input name='note' type='text' className='note' placeholder='Note' style={{top:top[1]}} onChange={AddressChange}></input>
                        </div>
                    </div>
                </div>
                <div className='payment-block'>
                    <div className='block-title'>
                        <h3>Payment Method</h3>
                    </div>
                    <div>
                        <div className='payment-options'>
                            <div className={`card ${(paymentOption=='Card')?'selected-option':''}`} onClick={()=>setOption('Card')}><p>Card</p></div>
                            <div className={`cash ${(paymentOption=='Cash')?'selected-option':''}`} onClick={()=>setOption('Cash')}><p>Cash</p></div>
                        </div>
                        <div className='card-options'>
                            {paymentOption=='Card' &&(<>  
                                <div className='logo-frame'>
                                    {myCards.length>0 && (
                                        <img src={cardBrandToIcon[myCards[selectedCard].brand]}></img>
                                    )}
                                </div>
                                <div  className='card-details'>
                                    <p>{(myCards.length==0)?'':`${(myCards[selectedCard].brand=='visa')?'Visa':'Mastercard'}....${myCards[selectedCard].last4}`}</p>
                                    <p className='card-expiry'>{(myCards.length==0)?'':`${myCards[selectedCard].expiry.slice(0,2)}/${myCards[selectedCard].expiry.slice(2)}`}</p>
                                </div> 
                                <img className='arrow' src={(cardsOpen)?'/Images/up-arrow.png':'/Images/down-arrow.png'} onClick={()=>{setCardsOpen(!cardsOpen); setHeight2((cardsOpen)?'0px':'210px')}}></img>
                            </>)}    
                            {paymentOption=='Cash' &&(<>  
                                <p className='payment-disclaimer'>Please have the exact amount ready for the service provider, to avoid issues with change</p>
                            </>)}    
                        </div>
                    </div>
                    {paymentOption=='Card' && (
                        <div className='card-list' style={{height:height2}}>
                            <p className='addCard-link' onClick={()=>setAddCard(true)}>Add a new card</p>
                            {myCards && myCards.length>0 && myCards.map((data, index)=>(
                            <div className='card-option'>
                                <div className='card-data' onClick={()=>setCard(index)}>
                                    <div className='logo-frame'>
                                        <img src={cardBrandToIcon[data.brand]}></img>
                                    </div>
                                    <div  className='card-details'>
                                        <p>{(data.brand=='visa')?'Visa':'Mastercard'}....{data.last4}</p>
                                        <p className='card-expiry'>{data.expiry.slice(0,2)}/{data.expiry.slice(2)}</p>
                                    </div>      
                                </div>
                                <img className='card-delete' src='/Images/delete.png' onClick={()=>{setDeleteCard(true);setToBeDeleted(index);}}></img>  
                            </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
            <div className='checkout-data'>
                <div className='block1'>
                    <p className='booking-statement'>Booking of {myBooking.service} from:</p>
                    <div className='data-holder'>
                        <div className='servicer-image'>
                            <div className='profile-frame'>
                                <img src={(servicer.profileLink)?servicer.profileLink:'/Images/user.png'}></img>
                            </div>
                        </div>
                        <div className='servicer-data'>
                            <p className='booking-name'>{servicer.name}</p>
                            <div className='rating'>
                                <p>{rating.toFixed(1)}</p>
                                <img src='/Images/star-yellow.png'></img>
                            </div>
                            <p className='booking-surname'>{servicer.surname}</p>
                            <p className='booking-jobs'>{(jobs)?jobs:'0'} Jobs</p>
                        </div>
                    </div>
                </div>
                <div className='block2'>
                    { myBooking.type=='negotiable' &&(
                        <div className='slot-card'>
                            <p className='sc-name'>Slot {myBooking.appointment.slot1}</p>
                            <p className='sc-fee'>FREE</p>
                            <p className='sc-period'>{negotiableSlots[myBooking.appointment.slot1]}</p>
                            <p className='sc-date'>{myBooking.appointment.date}</p>
                        </div>
                    )}
                    { myBooking.type=='fixed' && (
                        <div className='slot-card'>
                            <p className='sc-name'>Slot {myBooking.appointment.slot1}</p>
                            <p className='sc-fee'>FREE</p>
                            <p className='sc-period'>{Period(myBooking.appointment.slot1)}</p>
                            <p className='sc-date'>{myBooking.appointment.date}</p>
                        </div>
                    )}
                    { myBooking.type=='fixed' && myBooking.appointment.slot2 &&(
                        <div className='slot-card'>
                            <p className='sc-name'>Slot {myBooking.appointment.slot2}</p>
                            <p className='sc-fee'>FREE</p>
                            <p className='sc-period'>{Period(myBooking.appointment.slot2)}</p>
                            <p className='sc-date'>{myBooking.appointment.date}</p>
                        </div>
                    )}
                    { myBooking.service=='House Cleaning' &&(
                        <div className='house-card'>
                            <p className='hc-name'>Common Areas</p>
                            <p className='hc-fee'>R{ myBooking.house.commonCost}</p>
                            <div className='extras-block'>    
                                <p className='extra' style={{display:( myBooking.house.laundry)?'block':'none'}}>laundry</p>
                                <p className='extra' style={{display:( myBooking.house.cabinets)?'block':'none'}}>cabinets</p>
                                <p className='extra' style={{display:( myBooking.house.windows)?'block':'none'}}>windows</p>
                                <p className='extra' style={{display:( myBooking.house.fridge)?'block':'none'}}>fridge</p>
                                <p className='extra' style={{display:( myBooking.house.oven)?'block':'none'}}>oven</p>
                            </div>
                        </div>
                    )}
                    { myBooking.service=='House Cleaning' &&  myBooking.house.rooms.map((data,index)=>
                        <div className='room-card'>
                            <p className='rc-name'>Room {index+1}</p>
                            <p className='rc-fee'>R{(data==true)?'35':'15'}</p>
                            <p className='room-windows' style={{display:(data==true)?'block':'none'}}>windows</p>
                        </div>
                    )} 
                    { myBooking.service=='House Cleaning' &&  myBooking.house.bathrooms.map((data,index)=>
                        <div className='bathroom-card'>
                            <p className='bc-name'>Bathroom {index+1}</p>
                            <p className='bc-fee'>R{(data==true)?'55':'35'}</p>
                            <p className='bathroom-windows' style={{display:(data==true)?'block':'none'}}>windows</p>
                        </div>
                    )}
                    { myBooking.service=='Pool Cleaning' &&(
                        <div className='pool-card'>
                            <p className='pc-name'>Pool Cleaning</p>
                            <p className='pc-fee'>R{ myBooking.amount}</p>
                            <div className='extras-block'>
                                <p className='extra' style={{display:( myBooking.pool.tile)?'block':'none'}}>tile</p>
                                <p className='extra' style={{display:( myBooking.pool.deck)?'block':'none'}}>deck</p>
                                <p className='extra' style={{display:( myBooking.pool.inspection)?'block':'none'}}>inspection</p>
                            </div>
                        </div>
                    )}
                    { myBooking.service=='Gardening' &&(
                        <div className='garden-card'>
                            <p className='gc-name'>Pool Cleaning</p>
                            <p className='gc-fee'>R{ myBooking.amount}</p>
                            <div className='extras-block'>
                                <p className='extra' style={{display:( myBooking.garden.fertilize)?'block':'none'}}>fertilize</p>
                                <p className='extra' style={{display:( myBooking.garden.control)?'block':'none'}}>pest control</p>
                                <p className='extra' style={{display:( myBooking.garden.inspection)?'block':'none'}}>inspection</p>
                            </div>
                        </div>
                    )}
                    {myBooking && myBooking.service=='Car Cleaning' && myBooking.car.map((data)=>
                        <div className='car-card'>
                            <p className='cc-name'>{data.carType}</p>
                            <p className='cc-fee'>R{CarCost(data)}</p>
                            <div className='extras-block'>
                                <p className='extra' style={{display:(data.interior)?'block':'none'}}>interior</p>
                                <p className='extra' style={{display:(data.polish)?'block':'none'}}>polish tyres</p>
                            </div>
                        </div>
                    )}
                </div>
                <div className='block3'>
                    <div className='b3-left'>
                        <p className='subtotal-statement'>Sub-Total:</p>
                        <p className='fee-statement'>Service Fee:</p>
                        <p className='total-statement'>Total:</p>
                    </div>
                    <div className='b3-right'>
                        <p className='subtotal-value'>R{myBooking.amount}</p>
                        <p className='fee-value'>R30</p>
                        <p className='total-value'>R{myBooking.amount+30}</p>
                    </div>
                </div>
                <div className='block4'>
                    <button className='pay-button' onClick={Pay}>Pay</button>
                    <button className='cancel-button' onClick={()=>setCancelOpen(true)} >Cancel</button>
                </div>
            </div>
        </div>
        )

        return output;
    }
    else{
        const output = (<div id='Checkout'>    
            <div className='settings'>
                <h1 className='page-title'>No Booking To Checkout ....</h1>
                <div className='icon-block' >  
                    <img className='no-checkout' src='/Images/cart.png'></img>
                </div>
            </div>
            <div className='checkout-data'>
                <div className='block1'>
                  
                </div>
                <div className='block2'>

                </div>
                <div className='block3'>
                    <p className='subtotal-statement'>Sub-Total:</p>
                    <p className='fee-statement'>Service Fee:</p>
                    <p className='total-statement'>Total:</p>
                    <p className='subtotal-value'>R -</p>
                    <p className='fee-value'>R -</p>
                    <p className='total-value'>R -</p>
                </div>
                <div className='block4'>
                    <button className='pay-button' style={{opacity:'0.5'}}>Pay</button>
                    <button className='cancel-button' style={{opacity:'0.5'}}>Cancel</button>
                </div>
            </div>
        </div>
        )

        return output;
    }
}