import "../Style/SignUp2.css";
import { Link, useParams } from "react-router-dom";
import { useState, useEffect, useRef} from "react";
import { signUp2A } from "../Actions/User";
import { signUp2B } from "../Actions/Servicer";
import { UserDataContext } from "../Data/Session";
import { useContext } from "react";

function SignUp2(){
    const [isFocused1, setFocused1] = useState(false);
    const [isFocused2, setFocused2] = useState(false);
    const [isFocused3, setFocused3] = useState(false);
    const [isFocused4, setFocused4] = useState(false);
    const [isFocused40, setFocused40] = useState(false);
    const [isFocused5, setFocused5] = useState(false);
    const [display0, setDisplay0] = useState('block');
    const [display1, setDisplay1] = useState('block');
    const [display2, setDisplay2] = useState('block');
    const [display3, setDisplay3] = useState('block');
    const [display4, setDisplay4] = useState('block');
    const [display40, setDisplay40] = useState('block');
    const [display5, setDisplay5] = useState('block');
    const [display6, setDisplay6] = useState('block');
    const [display7, setDisplay7] = useState('block');
    const [userColor, setUC] = useState('white');
    const [userTXTColor, setUTC] = useState('black');
    const [servicerColor, setSC] = useState('white');
    const [servicerTXTColor, setSTC] = useState('black');
    const [servicerSelected, setSS] = useState(false);
    const [userSelected, setUS] = useState(false);
    const {sessionId} = useContext(UserDataContext); 

    const [input, setInput] = useState({
        code: null,
        email: null,
        password: null,
        password2:null,
        name: null,
        surname: null,
        number: null,
        type: null,
        promotions: false,
        terms: false
    })

    const {email} = useParams();

    useEffect(()=>{
        setInput({
            ...input,
            email: email
        })
    },[])
    

    const linkRef1 = useRef();
    const linkRef2 = useRef();
    
    function handleBlur1(){
        setFocused1(false);
    }
    function handleBlur2(){
        setFocused2(false);
    }
    function handleBlur3(){
        setFocused3(false);
    }
    function handleBlur4(){
        setFocused4(false);
    }
    function handleBlur40(){
        setFocused40(false);
    }
    function handleBlur5(){
        setFocused5(false);
    }
    
    function handleFocus1(){
        setFocused1(true);
    }

    function handleFocus2(){
        setFocused2(true);
    }

    function handleFocus3(){
        setFocused3(true);
    }

    function handleFocus4(){
        setFocused4(true);
    }

    function handleFocus40(){
        setFocused40(true);
    }
    function handleFocus5(){
        setFocused5(true);
    }

    function handleChange(e){
        const {name, value} = e.target;

        setInput({
            ...input,
            [name]: value
        })

        if(name=='code'){
            setDisplay0('none');
        }
        else if(name == 'name'){
            setDisplay1('none');
        }
        else if(name == 'surname'){
            setDisplay2('none');
        }
        else if(name == 'password'){
            setDisplay3('none');
        }
        else if(name == 'password2'){
            setDisplay4('none');
        }
        else if(name == 'number'){
            setDisplay5('none');
        }
    }

    function handleAgree(){
        setInput({
            ...input,
            terms:!input.terms
        });
        setDisplay7('none');
    }

    function handleConsent(){
        setInput({
            ...input,
            promotions:!input.promotions
        });
    }

    const handleSubmit = async(e)=>{
        e.preventDefault();
        let allGood = true;
        if(!input.code){
            setDisplay0('block');
            allGood = false;
        }
        if(!input.name){
            setDisplay1('block');
            allGood = false;
        }
        if(!input.surname){
            setDisplay2('block');
            allGood = false;
        }
        if(!input.password){
            setDisplay3('block');
            setDisplay4('block');
            allGood = false;
        }
        if(!input.number){
            setDisplay5('block');
            allGood = false;
        }
        if(input.terms==false){
            setDisplay7('block');
            allGood = false;
        }
        if(!input.type){
            setDisplay6('block');
            allGood = false;
        }
        if(allGood){
            let response = null;

            if(input.type=='User'){
                response = await signUp2A({...input,createdDate: new Date().getTime(),sessionId:sessionId});
            }
            else{
                response = await signUp2B({...input,createdDate: new Date().getTime(),sessionId:sessionId});
            }
            if(response && response.data && response.data.wrongCode){
                setDisplay0('block');
            }
            else if(response && response.data && response.data.accountCreated){
                if(response.data.type=='User'){
                    linkRef1.current.click();
                }
                else{
                    linkRef2.current.click();
                } 
            }
        }
    }

    function userClick(){
        setDisplay6('none');
        if(userSelected){
            setUS(false);
            setUC('white');
            setUTC('black');
            setInput({
                ...input,
                type:null 
            });
        }
        else{
            setUS(true);
            setUC('rgb(118, 118, 118)');
            setUTC('white');
            setSS(false);
            setSC('white');
            setSTC('black');
            setInput({
                ...input,
                type: 'User'
            });
        }
    }

    function servicerClick(){
        setDisplay6('none');
        if(servicerSelected){
            setSS(false);
            setSC('white');
            setSTC('black');
            setInput({
                ...input,
                type: null
            });
        }
        else{
            setSS(true);
            setSC('rgb(118, 118, 118)');
            setSTC('white');
            setUS(false);
            setUC('white');
            setUTC('black');
            setInput({
                ...input,
                type: 'Servicer'
            });
        }
    }

    const output = (<>
                        <div id="userDataPage">
                            <div className="logo-block">
                                <img src="/Images/logo3.png"></img>
                            </div>
                            <div className="emailUnderliner">
                                <div className="euLine1"></div>
                                <div className="euLine2"></div>
                            </div>
                            <div className="userInputPart">
                                <h2 className="WelcomeTxt">Lets make you a ScheduleEase member</h2>

                                <form onSubmit={handleSubmit}>
                                    {input.email && (<p className="emailCodeHeader">{`We've sent a code to ${input.email}`}</p>)}
                                    <div className="code">
                                        <input type="text" name="code" onFocus={handleFocus1} onBlur={handleBlur1} onChange={handleChange}></input>
                                        <label htmlFor="codeInput" className={`codePlaceholder ${isFocused1 || input.code ? 'active':''}`}>
                                            Code
                                        </label>
                                        <p className="required" style={{display:display0}}>Incorrect Code</p>
                                    </div>
                                    <div className="name-surname">
                                        <div className="name">
                                            <input type="text" name="name" onFocus={handleFocus2} onBlur={handleBlur2} onChange={handleChange}></input>
                                            <label htmlFor="nameInput" className={`namePlaceholder ${isFocused2 || input.name ? 'active':''}`}>
                                                Name
                                            </label>
                                            <p className="required" style={{display:display1}}>required</p>
                                        </div>
                                        <div className="surname">
                                            <input type="text" name="surname" onFocus={handleFocus3} onBlur={handleBlur3} onChange={handleChange}></input>
                                            <label htmlFor="surnameInput" className={`surnamePlaceholder ${isFocused3 || input.surname ? 'active':''}`}>
                                                Surname
                                            </label>
                                            <p className="required" style={{display:display2}}>required</p>
                                        </div>
                                    </div>
                                    <div className="password">
                                        <input type="text" name="password" onFocus={handleFocus4} onBlur={handleBlur4} onChange={handleChange}></input>
                                        <label htmlFor="passwordInput" className={`passwordPlaceholder ${isFocused4 || input.password ? 'active':''}`}>
                                            Password
                                        </label>
                                        <p className="required" style={{display:display3}}>required</p>
                                    </div>
                                    <div className="password2">
                                        <input type="text" name="password2" onFocus={handleFocus40} onBlur={handleBlur40} onChange={handleChange}></input>
                                        <label htmlFor="passwordInput2" className={`password2Placeholder ${isFocused40 || input.password2 ? 'active':''}`}>
                                            Password Check
                                        </label>
                                        <p className="required" style={{display:display4}}>required</p>
                                    </div>
                                    <div className="number">
                                        <input type="text" name="number" onFocus={handleFocus5} onBlur={handleBlur5} onChange={handleChange}></input>
                                        <label htmlFor="numberInput" className={`numberPlaceholder ${isFocused5 || input.number ? 'active':''}`}>
                                            Number
                                        </label>
                                        <p className="required" style={{display:display5}}>required</p>
                                    </div>
                                    <div className="account-type">
                                        <div className="accountInput" >
                                            <div className="userOption" style={{backgroundColor: userColor}} onClick={userClick}><p style={{color: userTXTColor}}>User</p></div>
                                            <div className="sellerOption" style={{backgroundColor: servicerColor}} onClick={servicerClick}><p style={{color: servicerTXTColor}}>Servicer</p></div>
                                        </div>
                                        <p className="required1" style={{display:display6}}>required</p>
                                    </div>
                                    <div className="promotions">
                                        <input type="checkbox" name="promotions" checked={input.consent} onChange={handleConsent}></input>
                                        <p>Sign up for the Monthly ScheduleEase Magazine & Promotions</p>
                                    </div>  
                                    <div className="terms">  
                                        <p className="required2" style={{display:display7}}>please select to proceed</p>
                                        <input type="checkbox" name="terms" checked={input.terms} onChange={handleAgree}></input>
                                        <p>I agree to ScheduleEase's</p>
                                        <Link>Privacy Policy</Link>
                                        <p>and</p>
                                        <Link>Terms of Use</Link>
                                    </div>
                                    <div className="submit">
                                        <button type='submit'>
                                            Continue
                                        </button>
                                    </div>

                                </form>   
                                <Link ref={linkRef1} to='/' style={{display:'none'}}></Link> 
                                <Link ref={linkRef2} to='/signUp3' style={{display:'none'}}></Link> 
                            </div>
                            
                        </div>
                    </>);

    return output;
}

export default SignUp2; 