import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, useMapEvents, useMap, Popup } from 'react-leaflet';
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import 'leaflet-geosearch/dist/geosearch.css';
import axios from 'axios';
import '../Style/LocationSelector.css';


export default function LocationSelector({setAddress, Address}){
    const [position, setPosition] = useState(null);

    const fetchAddress = async (lat, lng) => {
        const url = `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lng}`;
    
        try {
          const response = await axios.get(url);
          const result = response.data;
    
          if (result && result.address) {
            // Extract more fields from the response
            const { house_number, road, suburb, city, state, postcode, country } = result.address;
            setAddress({...Address,line1:house_number,line2:road});
          }
        } catch (error) {
          console.error('Error fetching address:', error);
        }
    };

    const MapComponent = () => {
      const map = useMapEvents({
        locationfound: (location) => {
            setPosition((prevPosition) => {
                const newLat = location.latlng.lat;
                const newLng = location.latlng.lng;
                if (prevPosition?.lat !== newLat || prevPosition?.lng !== newLng) {
                  return { lat: newLat, lng: newLng };
                }
                return prevPosition; // No state update if the same position
            });
          map.flyTo(location.latlng, map.getZoom());
        }
      });
  
      useEffect(() => {
        map.locate({ setView: true, maxZoom: 16 });
      }, []);
  
      return (position)?(<Marker position={position}>
                            <Popup>
                                A pretty CSS3 popup. <br /> Easily customizable.
                            </Popup>
                        </Marker>) 
                        : 
                        (<Marker position={[-33.9249, 18.4241]}>
                            <Popup>
                                A pretty CSS3 popup. <br /> Easily customizable.
                            </Popup>
                        </Marker>);
    };

    useEffect(()=>{
        if(position){
            fetchAddress(position.lat, position.lng);
        }
    },[position])

    const output = (
                        <MapContainer center={[51.505, -0.09]} style={{ height: '100%', width: '100%' }} zoom={13} scrollWheelZoom={false}>
                            <TileLayer
                              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <MapComponent />
                        </MapContainer>
                    );

    return output;
}