import React, { createContext, useContext, useState, useEffect } from 'react';
import io from 'socket.io-client';
import { Outlet, Link } from "react-router-dom";
import { getBookings } from '../Actions/Booking';
import { getSchedules } from '../Actions/Schedule';
import { getImages } from '../Actions/Image';
import { getMessages } from '../Actions/Message';
import { getRatings } from '../Actions/Rating';
import { getSearchs } from '../Actions/Search';
import { getServicers } from '../Actions/Servicer';
import { getUsers } from '../Actions/User';
import { getVideos } from '../Actions/Video';
import { getCards } from '../Actions/Card';
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid';

export const UserDataContext = createContext();

const sessionId = uuidv4();
const socket = io('https://scheduleease.co.za', {
  query: { sessionId: sessionId }
});

export function Session(){
  const [bookings, setBookings] = useState(null);
  const [schedules, setSchedules] = useState(null);
  const [images,setImages] = useState(null);
  const [messages,setMessages] = useState(null);
  const [ratings,setRatings] = useState(null);
  const [searchs,setSearchs] = useState(null);
  const [servicers,setServicers] = useState(null);
  const [users,setUsers] = useState(null);
  const [videos,setVideos] = useState(null);
  const [cards, setCards] = useState(null);
  const [userData, setUserData] = useState(null);

  const navigate = useNavigate()
  useEffect(()=>{
    // Retrieving data from localStorage
    const storedUserData = JSON.parse(localStorage.getItem('userdata'));
    if(storedUserData){
      setUserData(storedUserData);
    }
    // Listen for real-time updates from the server
    socket.on('signedIn', (data) => {
        setUserData(data);

        // Storing data in localStorage
        localStorage.setItem('userdata', JSON.stringify(data));
    });

    socket.on('userdataChange', (data) => {
       setUserData(data);
      // Storing data in localStorage
      localStorage.setItem('userdata', JSON.stringify(data));
    });

    socket.on('signOut', () => {
      setUserData(null);
      localStorage.removeItem('userdata');
      navigate('/', { replace: true })
    });
  
  },[])

  useEffect(()=>{
    const start = async () => {
      try {
        const [
          Bookings,
          Schedules,
          Images,
          Messages,
          Ratings,
          Searchs,
          Servicers,
          Users,
          Cards,
          Videos
        ] = await Promise.all([
          getBookings(),
          getSchedules(),
          getImages(),
          getMessages(),
          getRatings(),
          getSearchs(),
          getServicers(),
          getUsers(),
          getCards(),
          getVideos()        
        ]);
  
        setBookings(Bookings)
        setSchedules(Schedules)
        setImages(Images)
        setMessages(Messages)
        setRatings(Ratings)
        setSearchs(Searchs)
        setServicers(Servicers)
        setUsers(Users)
        setVideos(Videos) 
        setCards(Cards)
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };
    
    start();
  },[])

  useEffect(() => {

    // Listen for real-time updates from the server
    socket.on('Checkout', async() =>{
      navigate('/checkout');
    })
    socket.on('CheckoutComplete', async() =>{
      navigate('/services');
    })
    socket.on('BookingChange', async() => {
      const data = await getBookings();
      setBookings(data);
    });
    socket.on('CardChange', async() => {
      const data = await getCards();
      setCards(data);
    });
    socket.on('ScheduleChange', async() => {
      const data = await getSchedules();
      setSchedules(data);
    });
    socket.on('ImageChange', async() => {
      const data = await getImages();
      setImages(data);
    });
    socket.on('MessageChange', async() => {
      const data = await getMessages();
      setMessages(data);
    });
    socket.on('VideoChange', async() => {
      const data = await getVideos();
      setVideos(data);
    });
    socket.on('RatingChange', async() => {
      const data = await getRatings();
      setRatings(data);
    });
    socket.on('SearchChange', async() => {
      const data = await getSearchs();
      setSearchs(data);
    });
    socket.on('ServicerChange', async() => {
      const data = await getServicers();
      setServicers(data);
    });
    socket.on('UserChange', async() => {
      const data = await getUsers();
      setUsers(data);
    });

    // Cleanup the socket connection when the component unmounts
    return () => {
    };
  }, []);

  return (
    <UserDataContext.Provider value={{sessionId,bookings,cards,schedules,images,messages,ratings,searchs,servicers,users,videos,userData}}>
      <Outlet/>
    </UserDataContext.Provider>
  );
}

export {socket};